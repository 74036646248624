@import '../../styles/customMediaQueries.css';

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;

  @media (--viewportMedium) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.grid__title:not(.s) {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;

  @media (--viewportMedium) {
    font-size: 24px;
    font-weight: 600;
  }
}

.horizontalList:not(.s) {
  padding-left: 0;
}

.grid__description:not(.s) {
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  /* mb={2} mr={6} */

  @media (--viewportMedium) {
    font-size: 16px;
    margin-bottom: 24px;
    margin-right: 64px;
  }
}

.mobileHidden:not(.s) {
  display: none;

  @media (--viewportMedium) {
    display: grid;
  }
}

.desktopHidden:not(.s) {
  display: grid;

  @media (--viewportMedium) {
    display: none;
  }
}
