@import '../../../styles/customMediaQueries.css';

.root {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 24px;
  overflow-x: auto;
  padding: 0 16px;

  @media (--viewportMedium) {
    padding: 0 24px;
  }

  @media (min-width: 1320px) {
    grid-template-columns: repeat(4, 1fr);
    margin: 0;
  }

  @media (min-width: 1440px) {
    max-width: 1340px;
    padding: 0 10px;
    margin: auto;
  }
}

.rootSmallWidth {
  @media (min-width: 1440px) {
    max-width: calc(1096px + 20px);
  }
}

.root::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent;
}

.recentItemCardRoot {
  border-radius: 4px;
  height: auto;
  width: 300px;
  overflow: hidden;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;

  @media (min-width: 1320px) {
    width: unset;
  }

  @media (--viewportLarge) {
    margin: 0;
  }

  @media (min-width: 1440px) {
    width: auto;
  }
}
