@import '../../../../styles/customMediaQueries.css';

.root {
  border-radius: 4px;
  height: auto;
  width: 300px;
  overflow: hidden;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;

  @media (--viewportLarge) {
    margin: 0;
  }

  @media (min-width: 1440px) {
    width: auto;
  }
}

.root:hover {
  text-decoration: none;
}

.imageWrapper {
  color: black;
}

.listingImage {
  width: 100%;
  height: 174px;
  object-fit: cover;
  overflow: overlay;
  border-radius: 12px;
}

.itemDetails {
  position: relative;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.itemPricingDetails {
  position: relative;
  margin: 0 8px 8px 8px;
}

.itemTitle {
  margin-top: 0px;
  margin-bottom: 4px;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.itemDescription {
  margin-top: 4px;
  margin-bottom: 4px;
}

.itemFavoriteIcon {
  position: absolute;
  right: 10px;
  top: 16px;
}

.itemPrice {
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: var(--matterColorDark);
}

.itemFrameSize {
  margin-top: 8px;
  margin-bottom: 4px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
}

.divider {
  margin-top: 8px;
  margin-bottom: 4px;
  border-top: 1px solid #e9ecef;
}

.locationContainer {
  display: flex;
  flex-direction: row;
  height: 40px;
  align-items: center;
}

.itemLocation {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 8px;
  font-size: 12px;
  font-weight: 400;
}

.itemDetailsSpan {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

.listingCard {
  color: black;
}

.location {
  display: flex;
  width: 100%;
  align-items: center;
}

.locationIcon {
  height: 16px;
  width: 16px;
  margin-right: 4px;
}

.locationSpan {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

.frameSize {
  display: flex;
  width: 100%;
  align-items: center;
}

.frameSizeIcon {
  height: 14px;
  width: 14px;
  margin-right: 6px;
}

.description {
  margin-top: 4px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  max-height: 44px;
  overflow: hidden;
  white-space: pre-wrap;
  text-overflow: ellipsis;
}

.itemBadges {
  display: flex;
  flex-direction: row;
}

.badgeRoot {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 8px;
  gap: 4px;

  background: #edf1f0;
  border-radius: 15px;
}

.badgeText {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: var(--matterColorDark);
}

.likeButtonWrapper {
  position: absolute;
  right: 0;
  top: 0;
}

.likeButtonWrapper:hover {
  cursor: pointer;
}

.likeIcon {
  height: 24px;
  width: 24px;
}
