@import '../../styles/customMediaQueries.css';

.heroContainer {
  display: flex;
  flex-direction: column;
  min-height: 644px;
  height: 70vh;
  padding: 0;

  @media (--viewportMedium) {
    min-height: 500px;
    height: 70vh;
    max-height: none;
  }

  @media (--viewportLarge) {
    max-height: 800px;
    min-height: 600px;
    height: calc(70vh - var(--topbarHeightDesktop));
  }
}

.heroRoot {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.hero {
  flex-grow: 1;
  justify-content: flex-end;
  padding-bottom: 76px;

  @media (--viewportMedium) {
    padding-bottom: 83px;
  }

  @media (--viewportLarge) {
    justify-content: center;
    align-items: center;
    padding-top: 60px;
  }
}

.categoryContainer {
  margin-top: 36px;
}

.categoryContainerTitle {
  margin: 0 16px;

  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;

  @media (--viewportMedium) {
    padding: 0 24px;
    margin: 0;
  }

  @media (min-width: 1440px) {
    max-width: 1096px;
    margin: auto;
    padding: 0;
  }
}

.brandContainer {
  margin-top: 36px;
  margin-left: 16px;
  margin-right: 16px;
}

.mostRecentItemsContainer {
  margin-top: 32px;

  @media (--viewportMedium) {
    margin-top: 64px;
  }
}

.mostRecentItemsHeader {
  margin: 16px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;

  @media (--viewportMedium) {
    padding: 0 24px;
    margin: 16px 0;
  }

  @media (min-width: 1440px) {
    max-width: 1096px;
    margin: 16px auto;
    padding: 0;
  }

  @media (max-width: 340px) {
    flex-wrap: wrap;
  }
}

.mostRecentItemsHeader > button,
.landingSectionButton {
  height: 34px;
  width: fit-content;
  padding: 0 16px;
  white-space: nowrap;
}

.mostRecentItemsContainerTitle {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  margin-bottom: 0;
  margin-top: 0;

  @media (--viewportMedium) {
    font-size: 24px;
  }
}

.searchItemsContainer {
  margin-top: 16px;
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 60px;
}

.searchBarContainer {
  margin-top: 16px;
  margin-left: 16px;
  margin-right: 16px;

  @media (--viewportMedium) {
    display: none;
  }
}

.heroButton {
  composes: button buttonFont buttonText buttonSecondaryAccentBorder buttonSecondaryAccentColors from global;

  @media (--viewportMedium) {
    width: 300px;
    margin: auto;
  }
}

.footer {
  margin-top: 0;
}
